import React, { useState, useEffect } from "react";
import CalendarDatePicker from "../CalendarDatePicker";
import { fetchCarePerformanceData, fetchProgramList, fetchCareGraphData } from "../../../services/medengage/Service";
import moment from "moment";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import CallCompetionRatesBar from "../DashboardGraphsCNP/CallCompetionRatesBar";
import TaskCompletionRateBar from "../DashboardGraphsCNP/TaskCompletionRateBar";
import DetailedCareNavigatorPerformanceTable from './DetailedCareNavigatorPerformanceTable';
import MemberPathwayAssignedStatusPie from "../DashboardGraphsCNP/MemberPathwayAssignedStatusPie";
import MemberPathwayStatusPie from "../DashboardGraphs/MemberPathwayStatusPie";
import MembesAccrossPathwayMonthsBar from "../DashboardGraphsCNP/MembesAccrossPathwayMonthsBar";
import { decryptData } from "../../utils/Utils";

function ContentCareNavigatorPerformanceDetail() {

    const storedDates = localStorage.getItem('selectedDates') ? JSON.parse(decryptData(localStorage.getItem('selectedDates'))) : '';

    const [startDate, setStartDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[0]) : moment()
    );
    const [endDate, setEndDate] = useState(
        storedDates && storedDates.length === 4 ? moment(storedDates[1]) : moment()
    );
    const [selectedCondition, setSelectedCondition] = useState('');
    const [programGraph, setProgramGraph] = useState('');
    const [programList, setProgramList] = useState([]);
    const [conditionList, setConditionList] = useState([]);
    const [navigatorStats, setNavigatorStats] = useState({ 'onboardMembers': '', 'enrollMembers': '', 'conversionRate': '', 'avg_members': '', 'care_count': '', 'service_completion': '', 'transactions': '', 'homeCare': '', 'teleHealth': '', 'labCare': '', 'medication': '' });
    const [navigatorStatsNew, setNavigatorStatsNew] = useState({ 'callCompleteCount': '', 'taskCompleteRate': '', 'serviceCompleteRate': '', 'memebrPathwayCount': '' });
    // const [navigatorUsers, setNavigatorUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [seriesGraph, setSeriesGraph] = useState({
        memberPathway: [],
        callGraph: [],
        serviceGraph: [],
    })

    useEffect(() => {
        fetchDropDownList();
    }, []);


    useEffect(() => {
        const performFilterEffect = async () => {
            setLoading(true);
            await filterData();
        };

        performFilterEffect();
    }, [startDate, endDate, programGraph, selectedCondition]);


    const filterData = async () => {
        await fetchData();
        await fetchMembersProgramCare();
        setLoading(false);
        // await fetchMembersProgramTwo();
        // await fetchPathwayBreakup();
    };

    const fetchData = async () => {
        fetchCarePerformanceData(formatDate(startDate), formatDate(endDate), programGraph, selectedCondition)
            .then((response) => {
                const statsData = response.data.data.memberData;
                setNavigatorStats(prevState => ({
                    ...prevState, onboardMembers: statsData.onboardedMembers, enrollMembers: statsData.enrollMembers, conversionRate: statsData.enrollRate, avg_members: statsData.avgMembersPerCare, transactions: statsData.careNavigationTransactions, service_completion: statsData.servicePercentage, care_count: statsData.careCount,
                    homeCare: statsData.totalHomeCare, teleHealth: statsData.totalTele, labCare: statsData.totalLabCare, medication: statsData.totalMedication
                }));
                // setNavigatorUsers(response.data.data.navigatorUsers)
            });
    }

    const fetchMembersProgramCare = async () => {
        fetchCareGraphData(formatDate(startDate), formatDate(endDate), programGraph, selectedCondition)
            .then((response) => {
                const statsData = response.data.data;
                setNavigatorStatsNew(prevState => ({
                    ...prevState, callCompleteCount: statsData.callCompleteRate, taskCompleteRate: statsData.serviceCompleteRate, serviceComplete: statsData.serviceCompleteRate, memebrPathwayCount: statsData.totalMembersPathway
                }));
                setSeriesGraph(prevState => ({
                    ...prevState, memberPathway: response.data.data.memberPathwayStatus.series.data, callGraph: response.data.data.callCompleteGraph, serviceGraph: response.data.data.serviceCompleteGraph
                }));
            });
    }

    const fetchDropDownList = async () => {
        await fetchProgramList()
            .then((response) => {
                setProgramList(response.data.data.programList);
                setConditionList(response.data.data.conditionList);
            });
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    }

    const handleDateChange = ({ start, end }) => {
        setStartDate(start);
        setEndDate(end);
    };

    const handleChangeCondition = (event) => {
        setSelectedCondition(event.target.value);
    };

    const handleChangeProgramGraph = (event) => {
        setProgramGraph(event.target.value);
    };

    const renderCNP1Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of members enrolled in the program.
        </Tooltip>
    );

    const renderCNP2Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Total number of care navigators
        </Tooltip>
    );

    const renderCNP3Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Average number of members enrolled per care navigator
        </Tooltip>
    );

    const renderCNP4Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Service Completion Rate
        </Tooltip>
    );

    const renderCNP5Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Call Completion Rate
        </Tooltip>
    );

    const renderCNP6Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Task completion rate for care navigator.
        </Tooltip>
    );

    const renderCNP7Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Service completion rate for care navigator.
        </Tooltip>
    );

    const renderPP8Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Status of members onboarded: Completed, In Progress, De-Enrolled.
        </Tooltip>
    );

    const renderPP1Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of members who have enrolled in the program (Enrolled + Enroll later).
        </Tooltip>
    );

    const renderPP2Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Number of members enrolled in each pathway.
        </Tooltip>
    );

    const renderPP3Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Conversion Rate of members enrolled in the program.
        </Tooltip>
    );

    const renderPP12Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Care Navigation Transactions.
        </Tooltip>
    );

    const renderPP13Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Percentage of Task completed.
        </Tooltip>
    );

    const renderPP14Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Count of Home Care Task due by Care Navigators.
        </Tooltip>
    );

    const renderPP23Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Count of TeleHealth Task due by Care Navigators.
        </Tooltip>
    );

    const renderPP24Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Count of Lab Referrals Task due by Care Navigators.
        </Tooltip>
    );

    const renderPP25Tooltip = (props) => (
        <Tooltip className="custom-tooltip" {...props}>
            Count of Medication Task due by Care Navigators.
        </Tooltip>
    );

    return (
        <>
            <div className="container-fluid px-0 mb-3 ">

                <div className="row align-items-center pb-3 sticky-top-bar">
                    <div className="col-md ">
                        <h1 className="main-title">Care Navigator Performance</h1>
                    </div>

                </div>
                <div className='reports-programs mb-3'>
                    <div className="row align-items-center">
                        <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/dashboardresources/icons/carenavigatorperformance.png" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
                        </div>
                        <div className="col-md">
                            <p className="mb-3">Enables admins to view data and statistics on care navigator performance, highlighting important KPIs related to MemberConnect and service utilization.</p>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-4 pb-3 sticky-top-bar">
                    <div className="col-md-4">
                        <select className="form-select input-field-ui" id="SelectProgram" aria-label="Default select example" value={programGraph} onChange={handleChangeProgramGraph}>
                            <option value="">Select Program</option>
                            {programList.map(option => (
                                <option key={option.programId} value={option.programId}>{option.programName}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <select className="form-select input-field-ui" aria-label="Default select example" value={selectedCondition} onChange={handleChangeCondition}>
                            <option value="">Select Condition</option>
                            {conditionList.map(option => (
                                <option key={option.conditionId} value={option.conditionId}>{option.conditionName}</option>
                            ))}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <CalendarDatePicker startDate={startDate} endDate={endDate} onDateChange={handleDateChange} />
                    </div>
                </div>

                <div className="content-ProgramPerformance mb-3">
                    <div className="row text-center px-2">
                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.onboardMembers}</h1>
                                            <h5 className="card-text1">Members Onboarded
                                                <OverlayTrigger placement="top" overlay={renderPP1Tooltip}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                        {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP1Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.enrollMembers}</h1>
                                            <h5 className="card-text1">Members Enrolled
                                                <OverlayTrigger placement="top" overlay={renderCNP1Tooltip}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                        {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderCNP1Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.conversionRate}</h1>
                                            <h5 className="card-text1">Conversion Rate
                                                <OverlayTrigger placement="top" overlay={renderPP3Tooltip}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                        {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP3Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.care_count}</h1>
                                            <h5 className="card-text1">CareNavigators
                                                <OverlayTrigger placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Total number of CareNavigators</Tooltip>}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                        {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderCNP2Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.avg_members}</h1>
                                            <h5 className="card-text1">Average Members per CareNavigator
                                                <OverlayTrigger placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Average numbers of members enrolled per CareNavigator</Tooltip>}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                        {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderCNP3Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.service_completion}</h1>
                                            <h5 className="card-text1">Service Completion Rate
                                                <OverlayTrigger placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Percentage of services completed</Tooltip>}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                        {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderCNP4Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                    <div className="row text-center px-2 mt-2">
                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.transactions}</h1>
                                            <h5 className="card-text1">CareNavigator Transactions
                                                <OverlayTrigger placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Total number of transactions by CareNavigator</Tooltip>}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                        {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP12Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.homeCare}</h1>
                                            <h5 className="card-text1">Home Health Care Referrals
                                                <OverlayTrigger placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Total number of Home Health Care Referrals</Tooltip>}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                        {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP14Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.teleHealth}</h1>
                                            <h5 className="card-text1">TeleHealth Referrals
                                                <OverlayTrigger placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Total number of Telehealth Referrals</Tooltip>}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                        {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP23Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.labCare}</h1>
                                            <h5 className="card-text1">Lab Referrals
                                                <OverlayTrigger placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Total number of Lab Referrals</Tooltip>}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                        {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP24Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="col px-1" >
                            <div className={`card bg-grey`} style={{ borderRadius: '14px', border: 'none', height: "100%" }}>
                                <div className="card-body">
                                    <div className="row">
                                        <div className="col">
                                            <h1 className="card-title1">{navigatorStats.medication}</h1>
                                            <h5 className="card-text1">Medication Referrals
                                                <OverlayTrigger placement="top"
                                                    overlay={<Tooltip className="custom-tooltip">Total number of Medication Referrals</Tooltip>}>
                                                    <i className='bx bx-info-circle ms-1'></i>
                                                </OverlayTrigger>
                                            </h5>
                                        </div>
                                        {/* <div className="col-auto">
                                        <OverlayTrigger placement="top" overlay={renderPP25Tooltip}>
                                            <i className='bx bx-info-circle'></i>
                                        </OverlayTrigger>
                                    </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="row mb-3">

                    <div className="col-md-4 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title '>
                                <span>Call Completion Rate
                                    <OverlayTrigger placement="top"
                                        overlay={<Tooltip className="custom-tooltip">Percentage of calls completed to calls booked</Tooltip>}>
                                        <i className='bx bx-info-circle ms-1'></i>
                                    </OverlayTrigger>
                                </span>
                                <span className="dashboard-graph-number">{navigatorStatsNew.callCompleteCount}</span>
                            </h1>

                            {loading ? (
                                <></>
                            ) : (
                                seriesGraph.callGraph.status > 0 ? (
                                    <CallCompetionRatesBar dataMonth={seriesGraph.callGraph} />
                                ) : (
                                    <div className="emty-column">
                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                    </div>
                                )
                            )}
                        </div>
                    </div>


                    {/* <div className="col-md-3 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title '>
                                <span>Task Completion Rate
                                <OverlayTrigger placement="top" overlay={renderCNP6Tooltip}>
                                    <i className='bx bx-info-circle ms-2'></i>
                                </OverlayTrigger></span> 
                                <span className="dashboard-graph-number">{navigatorStatsNew.taskCompleteRate}</span>
                            </h1>

                            <TaskCompletionRateBar heading="Task"/>
                        </div>
                    </div> */}

                    <div className="col-md-4 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title '>
                                <span>Service Completion Rate
                                    <OverlayTrigger placement="top" overlay={renderCNP7Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span>
                                <span className="dashboard-graph-number">{navigatorStatsNew.serviceComplete}</span>
                            </h1>

                            {/* <TaskCompletionRateBar heading="Service"/> */}
                            {loading ? (
                                <></>
                            ) : (
                                seriesGraph.serviceGraph.status > 0 ? (
                                    <CallCompetionRatesBar dataMonth={seriesGraph.serviceGraph} />
                                ) : (
                                    <div className="emty-column">
                                        <h5 className="emty-title-graph">There are no data to display</h5>
                                    </div>
                                )
                            )}
                        </div>
                    </div>

                    <div className="col-md-4 mb-3">
                        <div className="dashboard-graph-box mb-3">
                            <h1 className='dashboard-graph-title '>
                                <span>Member Pathway <br /> Assigned Status
                                    <OverlayTrigger placement="top" overlay={renderPP8Tooltip}>
                                        <i className='bx bx-info-circle ms-2'></i>
                                    </OverlayTrigger></span>
                                <span className="dashboard-graph-number">{navigatorStatsNew.memebrPathwayCount}</span>
                            </h1>

                            <div className="mt-0">
                                {loading ? (
                                    <></>
                                ) : (
                                    seriesGraph.memberPathway ? (
                                        <MemberPathwayStatusPie series={seriesGraph.memberPathway} />
                                    ) : (
                                        <div className="emty-pie">
                                            <h5 className="emty-title-graph">There are no data to display</h5>
                                        </div>
                                    )
                                )}
                            </div>
                        </div>
                    </div>

                </div>



                <div className='content-CareNavigatorPerformance mb-5'>

                    <div className="row">
                        <div className="col-md-12">
                            <div className="table-responsive DetailedCareNavigatorPerformanceTable">
                                <DetailedCareNavigatorPerformanceTable startDate={formatDate(startDate)} endDate={formatDate(endDate)} programId={programGraph} conditionId={selectedCondition} />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className='content-CareNavigatorPerformance mb-3'>
                    <div className="row">
                        <div className="col-md-12">
                        <div className="table-responsive CareNavigatorPerformance-table">
                        <table className="table table-striped table-bordered mb-0">
                            <thead className="table-CareNavigatorPerformance">
                                <tr>
                                    <th scope="col">Enrolled Members</th>
                                    <th scope="col">Average members/CN</th>
                                    <th scope="col">CN Appointment Completion %</th>
                                    <th scope="col">Lab Test Completion %</th>
                                    <th scope="col">TM Appointment Completion %</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>{navigatorStats.enrollMembers}</td>
                                    <td>{navigatorStats.avg_members}</td>
                                    <td>{navigatorStats.appointmentCompletion}</td>
                                    <td>{navigatorStats.labTestCompletion}</td>
                                    <td>{navigatorStats.tmAppointmentCompletion}</td>
                                </tr>
                            
                            </tbody>
                            </table>
                        </div>
                        </div>
                    </div>
                </div> */}
                {/* 
                <div className='content-CareNavigatorPerformance mb-3'>
                    <div className="row">
                        
                        <div className="col-md-12 px-3 my-2">
                            <div className="card-columns">

                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h1 className="main-title">Total Migrated Members & Enrollment</h1>
                                        <h2 className="main-subtitle">1,000  <i class="bi bi-arrow-up"></i></h2>
                                        <h3 className="main-content">Total Migrated Members</h3>
                                        <div className="row">
                                            <div className="col-md-12 my-1">
                                                <div className="main-box">
                                                    <h4 className="text-graph">Enrolled Members</h4>
                                                    <h4 className="text-graph">800 <i class="bi bi-arrow-up"></i></h4>
                                                    <div className="graph-box bg-graph-pink" style={{width:"67%"}}></div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 my-1">
                                                <div className="main-box">
                                                    <h4 className="text-graph">Active Members</h4>
                                                    <h4 className="text-graph">700 <i class="bi bi-arrow-up"></i></h4>
                                                    <div className="graph-box bg-graph-grey" style={{width:"54%"}}></div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 my-1">
                                                <div className="main-box">
                                                    <h4 className="text-graph">Average Enrolled Members per Care Navigator</h4>
                                                    <h4 className="text-graph">100 <i class="bi bi-arrow-up"></i></h4>
                                                    <div className="graph-box bg-graph-grey" style={{width:"24%"}}></div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 my-1 pe-md-1">
                                                <div className="main-box1">
                                                    <div className="col-auto">
                                                        <h4 className="text-graph pb-1">Enrollment Rate</h4>
                                                        <h4 className="text-graph1">100 <i class="bi bi-arrow-up"></i></h4>
                                                    </div>
                                                    <div className="col px-2">
                                                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/mjzor/4jz374ylbe.png" className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 my-1 ps-md-1">
                                                <div className="main-box1">
                                                    <div className="col-auto">
                                                        <h4 className="text-graph pb-1">Active Rate</h4>
                                                        <h4 className="text-graph1">87.5% <i class="bi bi-arrow-up"></i></h4>
                                                    </div>
                                                    <div className="col px-2">
                                                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/klm98/74qefst48a.png" className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 my-1 pe-md-1">
                                                <div className="main-box1">
                                                    <div className="col-auto">
                                                        <h4 className="text-graph pb-1">Drop-out Rate</h4>
                                                        <h4 className="text-graph1">12.5% <i class="bi bi-arrow-down"></i></h4>
                                                    </div>
                                                    <div className="col px-2">
                                                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/sodot/va4acjh4xa.png" className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 my-1 ps-md-1">
                                                <div className="main-box1">
                                                    <div className="col-auto">
                                                        <h4 className="text-graph pb-1">Program Completion Rate</h4>
                                                        <h4 className="text-graph1">12.5% <i class="bi bi-arrow-up"></i></h4>
                                                    </div>
                                                    <div className="col px-2">
                                                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/wa3ld/teya7sz4y2.png" className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h1 className="main-title">Lab Tests</h1>
                                        <h2 className="main-subtitle">300 <i class="bi bi-arrow-up"></i></h2>
                                        <h3 className="main-content">Lab Test Referrals</h3>
                                        <div className="row">
                                           
                                            <div className="col-md-6 my-1 pe-md-1">
                                                <div className="main-box1">
                                                    <div className="col-auto">
                                                        <h4 className="text-graph pb-1">Lab Test Completion % <br/> within KPIs</h4>
                                                        <h4 className="text-graph1">80% <i class="bi bi-arrow-up"></i></h4>
                                                    </div>
                                                    <div className="col px-2">
                                                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/mjzor/4jz374ylbe.png" className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 my-1 ps-md-1">
                                                <div className="main-box1">
                                                    <div className="col-auto">
                                                        <h4 className="text-graph pb-1">Time to Lab <br/>Test Completion</h4>
                                                        <h4 className="text-graph1">3 days <i class="bi bi-arrow-up"></i></h4>
                                                    </div>
                                                    <div className="col px-2">
                                                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/klm98/74qefst48a.png" className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h1 className="main-title">Telehealth</h1>
                                        <h2 className="main-subtitle">250 <i class="bi bi-arrow-up"></i></h2>
                                        <h3 className="main-content">Telehealth Referrals</h3>
                                        <div className="row">
                                           
                                            <div className="col-md-6 my-1 pe-md-1">
                                                <div className="main-box1">
                                                    <div className="col-auto">
                                                        <h4 className="text-graph pb-1">Telehealth Appointment<br/> Completion %</h4>
                                                        <h4 className="text-graph1">80% <i class="bi bi-arrow-up"></i></h4>
                                                    </div>
                                                    <div className="col px-2">
                                                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/mjzor/4jz374ylbe.png" className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 my-1 ps-md-1">
                                                <div className="main-box1">
                                                    <div className="col-auto">
                                                        <h4 className="text-graph pb-1">Time to Lab Test<br/>  Completion</h4>
                                                        <h4 className="text-graph1">2 days <i class="bi bi-arrow-up"></i></h4>
                                                    </div>
                                                    <div className="col px-2">
                                                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/klm98/74qefst48a.png" className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h1 className="main-title">Home Care</h1>
                                        <h2 className="main-subtitle">150 <i class="bi bi-arrow-up"></i></h2>
                                        <h3 className="main-content">Home Care Referrals</h3>
                                        <div className="row">
                                           
                                            <div className="col-md-6 my-1 pe-md-1">
                                                <div className="main-box1">
                                                    <div className="col-auto">
                                                        <h4 className="text-graph pb-1">Home Care Completion % <br/>
                                                        Service/Provider/City</h4>
                                                        <h4 className="text-graph1">90% <i class="bi bi-arrow-up"></i></h4>
                                                    </div>
                                                    <div className="col px-2">
                                                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/mjzor/4jz374ylbe.png" className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 my-1 ps-md-1">
                                                <div className="main-box1">
                                                    <div className="col-auto">
                                                        <h4 className="text-graph pb-1">Time to Lab Test<br/>
                                                        Completion</h4>
                                                        <h4 className="text-graph1">4 Days <i class="bi bi-arrow-up"></i></h4>
                                                    </div>
                                                    <div className="col px-2">
                                                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/klm98/74qefst48a.png" className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h1 className="main-title">Digital Engagement</h1>
                                        <h2 className="main-subtitle">1,000  <i class="bi bi-arrow-up"></i></h2>
                                        <h3 className="main-content">Total Migrated Members</h3>
                                        <div className="row">
                                            <div className="col-md-12 my-1">
                                                <div className="main-box">
                                                    <h4 className="text-graph">Digital Engagement</h4>
                                                    <h4 className="text-graph">75% <i class="bi bi-arrow-up"></i></h4>
                                                    <div className="graph-box bg-graph-grey" style={{width:"54%"}}></div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 my-1">
                                                <div className="main-box">
                                                    <h4 className="text-graph">NPS</h4>
                                                    <h4 className="text-graph">8.5 <i class="bi bi-arrow-up"></i></h4>
                                                    <div className="graph-box bg-graph-grey" style={{width:"24%"}}></div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h1 className="main-title">Calls & Follow-ups</h1>
                                        <h2 className="main-subtitle">1,500  <i class="bi bi-arrow-up"></i></h2>
                                        <h3 className="main-content">Attempted Calls</h3>
                                        <div className="row">
                                            <div className="col-md-12 my-1">
                                                <div className="main-box">
                                                    <h4 className="text-graph">Successful Follow-up Calls</h4>
                                                    <h4 className="text-graph">1,200 <i class="bi bi-arrow-up"></i></h4>
                                                    <div className="graph-box bg-graph-pink" style={{width:"67%"}}></div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 my-1">
                                                <div className="main-box">
                                                    <h4 className="text-graph">Average Call Duration</h4>
                                                    <h4 className="text-graph">10 minutes <i class="bi bi-arrow-down"></i></h4>
                                                    <div className="graph-box bg-graph-grey" style={{width:"54%"}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h1 className="main-title">Diabetes Control</h1>
                                        <h2 className="main-subtitle">65%  <i class="bi bi-arrow-up"></i></h2>
                                        <h3 className="main-content">Well Controlled Diabetes (At-enrollment)</h3>
                                        <div className="row">
                                            <div className="col-md-12 my-1">
                                                <div className="main-box">
                                                    <h4 className="text-graph">Intermittent Poorly Controlled Diabetes</h4>
                                                    <h4 className="text-graph">20% <i class="bi bi-arrow-down"></i></h4>
                                                    <div className="graph-box bg-graph-grey" style={{width:"27%"}}></div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 my-1">
                                                <div className="main-box">
                                                    <h4 className="text-graph">Persistent Poorly Controlled Diabetes</h4>
                                                    <h4 className="text-graph">15% <i class="bi bi-arrow-down"></i></h4>
                                                    <div className="graph-box bg-graph-grey" style={{width:"34%"}}></div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 my-1">
                                                <div className="main-box">
                                                    <h4 className="text-graph">Well Controlled Diabetes (Real-Time)</h4>
                                                    <h4 className="text-graph">75% <i class="bi bi-arrow-up"></i></h4>
                                                    <div className="graph-box bg-graph-pink" style={{width:"34%"}}></div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 my-1">
                                                <div className="main-box">
                                                    <h4 className="text-graph">Intermittent Poorly Controlled Diabetes (Real-Time)</h4>
                                                    <h4 className="text-graph">15% <i class="bi bi-arrow-down"></i></h4>
                                                    <div className="graph-box bg-graph-grey" style={{width:"34%"}}></div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 my-1">
                                                <div className="main-box">
                                                    <h4 className="text-graph">Persistent Poorly Controlled Diabetes (Real-Time)</h4>
                                                    <h4 className="text-graph">15% <i class="bi bi-arrow-down"></i></h4>
                                                    <div className="graph-box bg-graph-grey" style={{width:"34%"}}></div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 my-1">
                                                <div className="main-box">
                                                    <h4 className="text-graph">Well Controlled Diabetes (Change%)</h4>
                                                    <h4 className="text-graph">+5% <i class="bi bi-arrow-down"></i></h4>
                                                    <div className="graph-box bg-graph-grey" style={{width:"34%"}}></div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 my-1">
                                                <div className="main-box">
                                                    <h4 className="text-graph">Intermittent Poorly Controlled Diabetes (Change%)</h4>
                                                    <h4 className="text-graph">-3% <i class="bi bi-arrow-down"></i></h4>
                                                    <div className="graph-box bg-graph-grey" style={{width:"34%"}}></div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 my-1">
                                                <div className="main-box">
                                                    <h4 className="text-graph">Persistent Poorly Controlled Diabetes (Change%)</h4>
                                                    <h4 className="text-graph">-2% <i class="bi bi-arrow-down"></i></h4>
                                                    <div className="graph-box bg-graph-grey" style={{width:"34%"}}></div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h1 className="main-title">Center of Excellence</h1>
                                        <h2 className="main-subtitle">500  <i class="bi bi-arrow-up"></i></h2>
                                        <h3 className="main-content">Excellence Referrals</h3>
                                        <div className="row">
                                            <div className="col-md-12 my-1">
                                                <div className="main-box">
                                                    <h4 className="text-graph">Center of Excellence Referrals</h4>
                                                    <h4 className="text-graph">120 <i class="bi bi-arrow-up"></i></h4>
                                                    <div className="graph-box bg-graph-grey" style={{width:"67%"}}></div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 my-1">
                                                <div className="main-box">
                                                    <h4 className="text-graph">Center of Excellence Completion % within KPIs</h4>
                                                    <h4 className="text-graph">88% <i class="bi bi-arrow-up"></i></h4>
                                                    <div className="graph-box bg-graph-grey" style={{width:"54%"}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h1 className="main-title">Medication Refill & Delivery</h1>
                                        <h2 className="main-subtitle">200 <i class="bi bi-arrow-up"></i></h2>
                                        <h3 className="main-content">Medication Refill / Delivery Referrals</h3>
                                        <div className="row">
                                           
                                            <div className="col-md-6 my-1 pe-md-1">
                                                <div className="main-box1">
                                                    <div className="col-auto">
                                                        <h4 className="text-graph pb-1">Medication Refill / Delivery<br/>
                                                        Completion % within KPIs</h4>
                                                        <h4 className="text-graph1">90% <i class="bi bi-arrow-up"></i></h4>
                                                    </div>
                                                    <div className="col px-2">
                                                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/mjzor/4jz374ylbe.png" className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-6 my-1 ps-md-1">
                                                <div className="main-box1">
                                                    <div className="col-auto">
                                                        <h4 className="text-graph pb-1">Time to Medication<br/>
                                                        Delivery Completion</h4>
                                                        <h4 className="text-graph1">2 Days <i class="bi bi-arrow-down"></i></h4>
                                                    </div>
                                                    <div className="col px-2">
                                                        <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/klm98/74qefst48a.png" className="img-fluid" alt="" />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                                <div className="card mb-3">
                                    <div className="card-body">
                                        <h1 className="main-title">Claim Costs</h1>
                                        <h2 className="main-subtitle">500  <i class="bi bi-arrow-up"></i></h2>
                                        <h3 className="main-content">Excellence Referrals</h3>
                                        <div className="row">
                                            <div className="col-md-12 my-1">
                                                <div className="main-box">
                                                    <h4 className="text-graph">Total Claim Cost (Pre-enrolment)</h4>
                                                    <h4 className="text-graph">$500,000 <i class="bi bi-arrow-up"></i></h4>
                                                    <div className="graph-box bg-graph-pink" style={{width:"27%"}}></div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 my-1">
                                                <div className="main-box">
                                                    <h4 className="text-graph">Total Claim Cost (Post-enrolment)</h4>
                                                    <h4 className="text-graph">$450,000 <i class="bi bi-arrow-up"></i></h4>
                                                    <div className="graph-box bg-graph-pink" style={{width:"54%"}}></div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 my-1">
                                                <div className="main-box">
                                                    <h4 className="text-graph">Claim Cost Change</h4>
                                                    <h4 className="text-graph">-10% <i class="bi bi-arrow-down"></i></h4>
                                                    <div className="graph-box bg-graph-grey" style={{width:"54%"}}></div>
                                                </div>
                                            </div>

                                            <div className="col-md-12 my-1">
                                                <div className="main-box">
                                                    <h4 className="text-graph">Lives Touched (Transactions)</h4>
                                                    <h4 className="text-graph">3500 <i class="bi bi-arrow-up"></i></h4>
                                                    <div className="graph-box bg-graph-pink" style={{width:"54%"}}></div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </div>
                       
                    </div>
                </div> */}

            </div>




        </>
    );
}

export default ContentCareNavigatorPerformanceDetail;
