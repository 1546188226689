import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import './Sidenavmenubar.css';
import { decruptCipherJson } from '../utils/CommonLibrary';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { encryptData, decryptData } from '../utils/Utils';

const Sidenavmenubar = () => {
  
  const [isOpen, setIsOpen] = useState(false);
  const [tabMenu, setTabMenu] = useState('');
  const storedCurrentSelect = localStorage.getItem('activeNavItem') ? decryptData(localStorage.getItem('activeNavItem')) : '';
  
  const [activeItem, setActiveItem] = useState(
    () => storedCurrentSelect
  );

  const checkScreenWidth = () => {
    if (window.innerWidth <= 1920) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  };

  useEffect(() => {

    const menuArr = localStorage.getItem('tabs') ? localStorage.getItem('tabs') : '';
    const menuArrData = decruptCipherJson(menuArr);
    setTabMenu(menuArrData);
    
    

    if(storedCurrentSelect == '' || storedCurrentSelect == null){
      setActiveItem(menuArrData[0]?.label)
    }
    
    checkScreenWidth();
    window.addEventListener('resize', checkScreenWidth);

    return () => {
      window.removeEventListener('resize', checkScreenWidth);
    };
  }, []);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  const handleClick = (itemLabel) => {
    setActiveItem(itemLabel);
    localStorage.setItem('activeNavItem', itemLabel);
    localStorage.setItem('activeNavItem', encryptData(itemLabel));
  };


{/* <Link to={item.path}  className={location.pathname === item.path ? 'active' : ''}> */}

  return (
    <div className={`sidebar ${isOpen ? '' : 'open'}`}>
      <div className="logo-details">
        <div className="logo_name mt-2">
          <div className="navbar-brand" >
            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/t0v4b/kj547l32rl.png" style={{ width: "134px" }} alt="" />
          </div>
        </div>
        <i className='bx bx-menu' id="btn" onClick={toggleSidebar}></i>
      </div>
      <ul className="nav-list mt-3">
        {tabMenu ?
          tabMenu.map((item, index) => (
            <li key={`menu_${index}`} onClick={() => handleClick(item.label)}>
              <OverlayTrigger placement="right" overlay={<Tooltip className="custom-tooltip tooltip-custom-new">{item.label}</Tooltip>}>
                <Link to={item.path} className={activeItem === item.label ? 'active' : ''}>
                  <i className={item.icon}></i>
                  <span className="links_name">{item.label}</span>
                </Link>
              </OverlayTrigger>
            </li>
          )) 
          : null
        }
      </ul>
    </div>
  );
}

export default Sidenavmenubar;
