import common_axios from "./commonAxios";


let baseURL = common_axios.defaults.baseURL + 'carenavigator/';


export const dashboardPendingThisWeek = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'dashboard_pending_this_week', params, cancelToken)
    return dataPass;
};

export const fetchWeeklySummary = (weekCount, cancelToken) => {
    const params = { 'weekCount': weekCount }
    var dataPass = common_axios.post(baseURL + 'fetch_weekly_summary', params, cancelToken)
    return dataPass;
};

export const fetchUsersList = (appliedFilter, searchStr, pageNumber, cancelToken) => {
    const params = { 'searchStr': searchStr, 'pageNumber': pageNumber }
    var dataPass = common_axios.post(baseURL + 'fetch_users_list', params, cancelToken)
    return dataPass;
};

export const searchMedicationDrugs = (searchStr, cancelToken) => {
    const params = { 'searchStr': searchStr }
    var dataPass = common_axios.post(baseURL + 'search_medication_drugs', params, cancelToken)
    return dataPass;
};

export const fetchAssessmentCategories = (categoryType, cancelToken) => {
    const params = { 'categoryType': categoryType }
    var dataPass = common_axios.post(baseURL + 'fetch_assessment_categories', params, cancelToken)
    return dataPass;
};

export const fetchAssessmentServices = (categoryId, cancelToken) => {
    const params = { 'categoryId': categoryId }
    var dataPass = common_axios.post(baseURL + 'fetch_assessment_services', params, cancelToken)
    return dataPass;
};

export const addAssessment = (userId, formData, cancelToken) => {
    const params = { 'userId': userId, 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'add_assessment', params, cancelToken)
    return dataPass;
};

export const fetchFiltersList = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'filterslist', params, cancelToken)
    return dataPass;
};

export const fetchPieData = (pieGraphType, extraParams, cancelToken) => {
    const params = { 'extraParams': extraParams }
    var dataPass = common_axios.post(baseURL + pieGraphType, params, cancelToken)
    return dataPass;
};

export const fetchColumnData = (graphType, extraParams, cancelToken) => {
    const params = { 'extraParams': extraParams }
    var dataPass = common_axios.post(baseURL + graphType, params, cancelToken)
    return dataPass;
};

export const fetchLineData = (graphType, extraParams, cancelToken) => {
    const params = { 'extraParams': extraParams }
    var dataPass = common_axios.post(baseURL + graphType, params, cancelToken)
    return dataPass;
};

export const fetchTodaysAppointmentsCount = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_todays_appointments_count', params, cancelToken)
    return dataPass;
};

export const fetchTodayAppointmentsList = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_today_appointments_list', params, cancelToken)
    return dataPass;
};

export const fetchOtherTasksList = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_other_tasks_list', params, cancelToken)
    return dataPass;
};


export const fetchWorkingPlansAndBreaks = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_working_plans_and_breaks', params, cancelToken)
    return dataPass;
};

export const saveAppointmentBreak = (start, end, reason, cancelToken) => {
    const params = { 'start': start, 'end': end, 'reason': reason }
    var dataPass = common_axios.post(baseURL + 'save_appointment_break', params, cancelToken)
    return dataPass;
};

export const saveAppointmentSlots = (slotDay, slotString, slotCount, cancelToken) => {
    const params = { 'slotDay': slotDay, 'slotString': slotString, 'slotCount': slotCount }
    var dataPass = common_axios.post(baseURL + 'save_appointment_slots', params, cancelToken)
    return dataPass;
};

export const removeAppointmentBreak = (breakId, cancelToken) => {
    const params = { 'breakId': breakId }
    var dataPass = common_axios.post(baseURL + 'remove_appointment_break', params, cancelToken)
    return dataPass;
};

export const fetchCalendarCalls = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_calendar_calls', params, cancelToken)
    return dataPass;
};


export const fetchUserProfile = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'fetch_user_profile', params, cancelToken)
    return dataPass;
};

export const weightHistory = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'weight_history', params, cancelToken)
    return dataPass;
};

export const hraHistory = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'hra_history', params, cancelToken)
    return dataPass;
};

export const addWeight = (userId, weight, height, cancelToken) => {
    const params = { 'userId': userId, 'weight': weight, 'height': height }
    var dataPass = common_axios.post(baseURL + 'add_weight', params, cancelToken)
    return dataPass;
};

export const disEnrollRequest = (userId, disEnrollmentNote, disEnrollmentReason, cancelToken) => {
    const params = { 'userId': userId, 'disEnrollmentNote': disEnrollmentNote, 'disEnrollmentReason': disEnrollmentReason }
    var dataPass = common_axios.post(baseURL + 'request_disenrollment', params, cancelToken)
    return dataPass;
};

export const fetchPathways = (userId, programId, conditionId, cancelToken) => {
    const params = { 'userId': userId, 'programId': programId, 'conditionId': conditionId }
    var dataPass = common_axios.post(baseURL + 'fetch_pathways', params, cancelToken)
    return dataPass;
};

export const assignPathway = (userId, programId, conditionId, oldPathwayId, pathwayId, notes, hba1c, date, cancelToken) => {
    const params = { 'userId': userId, 'programId': programId, 'conditionId': conditionId, 'oldPathwayId': oldPathwayId, 'pathwayId': pathwayId, 'notes': notes, 'hba1c': hba1c, 'date': date }
    var dataPass = common_axios.post(baseURL + 'assign_pathway', params, cancelToken)
    return dataPass;
};

export const fetchLastHRA = (userId, hraType, cancelToken) => {
    const params = { 'userId': userId, 'hraType': hraType }
    var dataPass = common_axios.post(baseURL + 'fetch_last_hra', params, cancelToken)
    return dataPass;
};

export const saveHRA = (userId, hraJson, hraType, hraScore, hraObservation, hraVersion, cancelToken) => {
    const params = { 'userId': userId, 'hraJson': hraJson, 'hraType': hraType, 'hraScore': hraScore, 'hraObservation': hraObservation, 'hraVersion': hraVersion }
    var dataPass = common_axios.post(baseURL + 'save_hra', params, cancelToken)
    return dataPass;
};

export const fetchMemberServices = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'fetch_member_services', params, cancelToken)
    return dataPass;
};

export const fetchMemberServicesMonthCategoryWise = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'fetch_member_services_month_category_wise', params, cancelToken)
    return dataPass;
};

export const fetchLabs = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_labs', params, cancelToken)
    return dataPass;
};

export const bookMemberConditionServices = (userId, recordId, formData, serviceType, cancelToken) => {
    const params = { 'userId': userId, 'recordId': recordId, 'formData': formData, 'serviceType': serviceType }
    var dataPass = common_axios.post(baseURL + 'book_member_condition_services', params, cancelToken)
    return dataPass;
};

export const updateMemberConditionServicesStatus = (userId, recordId, formData, serviceType, cancelToken) => {
    const params = { 'userId': userId, 'recordId': recordId, 'formData': formData, 'serviceType': serviceType }
    var dataPass = common_axios.post(baseURL + 'update_member_condition_services_status', params, cancelToken)
    return dataPass;
};

export const bookMemberServices = (userId, recordId, serviceType, formData, cancelToken) => {
    const params = { 'userId': userId, 'recordId': recordId, 'formData': formData, 'serviceType': serviceType }
    var dataPass = common_axios.post(baseURL + 'book_member_services', params, cancelToken)
    return dataPass;
};

export const updateMemberServicesStatus = (userId, recordId, formData, serviceType, cancelToken) => {
    const params = { 'userId': userId, 'recordId': recordId, 'formData': formData, 'serviceType': serviceType }
    var dataPass = common_axios.post(baseURL + 'update_member_services_status', params, cancelToken)
    return dataPass;
};

export const fetchServiceNotes = (userId, recordId, serviceType, cancelToken) => {
    const params = { 'userId': userId, 'recordId': recordId, 'serviceType': serviceType }
    var dataPass = common_axios.post(baseURL + 'fetch_service_notes', params, cancelToken)
    return dataPass;
};


export const fetchNotifications = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_notifications', params, cancelToken)
    return dataPass;
};

export const fetchNotificationsList = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_notifications_list', params, cancelToken)
    return dataPass;
};

export const updateNotifications = (notificationId, action, cancelToken) => {
    const params = { 'notificationId': notificationId, 'action': action }
    var dataPass = common_axios.post(baseURL + 'update_notifications', params, cancelToken)
    return dataPass;
};

export const fetchMemberConditionServices = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'fetch_member_condition_services', params, cancelToken)
    return dataPass;
};

export const fetchMemberPersonalData = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'fetch_member_personal_data', params, cancelToken)
    return dataPass;
};

export const checkMembership = (membershipNo, cancelToken) => {
    const params = { 'membershipNo': membershipNo }
    var dataPass = common_axios.post(baseURL + 'check_member_details', params, cancelToken)
    return dataPass;
};

export const saveNewMember = (formData, memberType, cancelToken) => {
    const params = { 'formData': formData, 'memberType': memberType }
    var dataPass = common_axios.post(baseURL + 'save_new_member', params, cancelToken)
    return dataPass;
};

export const saveMedication = (formData, cancelToken) => {
    const params = { 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'save_medication_data', params, cancelToken)
    return dataPass;
};

export const fetchFormData = (cancelToken) => {
    var dataPass = common_axios.post(baseURL + 'fetch_form_data', cancelToken)
    return dataPass;
};

export const saveLabRequest = (formData, cancelToken) => {
    const params = { 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'save_lab_request', params, cancelToken)
    return dataPass;
};

export const fetchFormDataBase = (cancelToken) => {
    var dataPass = common_axios.post(baseURL + 'fetch_home_base', cancelToken)
    return dataPass;
};

export const fetchFormDataMedication = (cancelToken) => {
    var dataPass = common_axios.post(baseURL + 'fetch_medication_form', cancelToken)
    return dataPass;
};

export const fetchHomeCare = (cancelToken) => {
    var dataPass = common_axios.post(baseURL + 'fetch_home_care', cancelToken)
    return dataPass;
};

export const saveHomeCareRequest = (formData, cancelToken) => {
    const params = { 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'save_home_care', params, cancelToken)
    return dataPass;
};

export const saveHomeBase = (formData, cancelToken) => {
    const params = { 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'save_home_base', params, cancelToken)
    return dataPass;
};

export const fetch_soft_number = (data) => {
    return common_axios.post(baseURL + 'fetch_soft_number', data);
}

export const fetchsoftphonetoken = (data) => {
    return common_axios.post(baseURL + 'fetchsoftphonetoken', data);
}

export const make_soft_phone_call = (data) => {
    return common_axios.post(baseURL + 'make_soft_phone_call', data);
}

export const cn_call_disconnect = (data) => {
    return common_axios.post(baseURL + 'cn_call_disconnect', data);
}

export const cn_call_hold = (data) => {
    return common_axios.post(baseURL + 'cn_call_hold', data);
}

export const cn_call_unhold = (data) => {
    return common_axios.post(baseURL + 'cn_call_unhold', data);
}

export const cn_call_user_status = (data) => {
    return common_axios.post(baseURL + 'cn_call_user_status', data);
}

export const soft_number_logger = (data) => {
    return common_axios.post(baseURL + 'soft_number_logger', data);
}

export const fetchServiceCategories = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_service_categories', params, cancelToken)
    return dataPass;
};

export const fetchServiceByCategory = (categoryId, cancelToken) => {
    const params = { 'categoryId': categoryId }
    var dataPass = common_axios.post(baseURL + 'fetch_service_by_category', params, cancelToken)
    return dataPass;
};

export const addMemberAdditionalServices = (userId, formData, cancelToken) => {
    const params = { 'userId': userId, 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'add_member_additional_services', params, cancelToken)
    return dataPass;
};

export const addMemberConditionServices = (userId, formData, cancelToken) => {
    const params = { 'userId': userId, 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'add_member_condition_services', params, cancelToken)
    return dataPass;
};


export const cn_call_disposition = (data) => {
    var dataPass = common_axios.post(baseURL + 'cn_call_disposition', data);
    return dataPass;
}

export const fetchLastAssessment = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'fetch_last_assessment', params, cancelToken)
    return dataPass;
};

export const data_disposition_list = () => {
    let dataPass = common_axios.get(baseURL + 'data_disposition_list');
    return dataPass;
}

export const fetchsoftphonetokencn = (data) => {
    let dataPass = common_axios.post(baseURL + 'fetchsoftphonetokencn', data);
    return dataPass;
}

export const clearfirebasecnnode = (data) => {
    let dataPass = common_axios.post(baseURL + 'clearfirebasecnnode', data);
    return dataPass;
}

export const fetchcn = () => {
    let dataPass = common_axios.get(baseURL + 'fetchcn');
    return dataPass;
}

export const pathwayBreakup = (conditionId, cancelToken) => {
    const params = { 'conditionId': conditionId }
    var dataPass = common_axios.post(baseURL + 'pathway_breakup_v2', params, cancelToken)
    return dataPass;
};

export const fetchConditions = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_conditions', params, cancelToken)
    return dataPass;
};

export const cn_call_mute = (data) => {
    var dataPass = common_axios.post(baseURL + 'cn_call_mute', data);
    return dataPass;
}

export const fetchReportTypes = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_report_types', params, cancelToken)
    return dataPass;
};

export const fetchHealthlockerFiles = (userId, reportTypeId, cancelToken) => {
    const params = { 'userId': userId, 'reportTypeId': reportTypeId }
    var dataPass = common_axios.post(baseURL + 'fetch_healthlocker_files', params, cancelToken)
    return dataPass;
};

export const fetchHealthLockerFileInfo = (userId, fileId, cancelToken) => {
    const params = { 'userId': userId, 'fileId': fileId }
    var dataPass = common_axios.post(baseURL + 'fetch_healthlocker_file_info', params, cancelToken)
    return dataPass;
};

export const removeHealthLockerFiles = (fileId, cancelToken) => {
    const params = { 'fileId': fileId }
    var dataPass = common_axios.post(baseURL + 'remove_healthlocker_files', params, cancelToken)
    return dataPass;
};

export const addHealthLockerFile = (userId, formData, cancelToken) => {
    const params = { 'userId': userId, 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'add_healthlocker_file', params, cancelToken)
    return dataPass;
};

export const updateHealthlockerFile = (userId, formData, cancelToken) => {
    const params = { 'userId': userId, 'formData': formData }
    var dataPass = common_axios.post(baseURL + 'update_healthlocker_file', params, cancelToken)
    return dataPass;
};

export const fetchPathwayChangeLogs = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'fetch_pathway_change_logs', params, cancelToken)
    return dataPass;
};


export const services_action = (data) => {
    const dataPass = common_axios.post(baseURL + 'services_action', data);
    return dataPass;
}

export const fetchServiceEventHistory = (userId, pathwayId, cancelToken) => {
    const params = { 'userId': userId, 'pathwayId': pathwayId }
    var dataPass = common_axios.post(baseURL + 'fetch_service_event_history', params, cancelToken)
    return dataPass;
};

export const fetchPathwayServicesMonthCategoryWise = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'fetch_pathway_services_month_category_wise', params, cancelToken)
    return dataPass;
};

export const fetchGraphSegmentData = (segment, graphType, cancelToken) => {
    const params = { 'segment': segment, 'graphType': graphType }
    var dataPass = common_axios.post(baseURL + 'fetch_graph_segment_data', params, cancelToken)
    return dataPass;
};

export const fetchCallsHistory = (userId, cancelToken) => {
    const params = { 'userId': userId }
    var dataPass = common_axios.post(baseURL + 'fetch_calls_history', params, cancelToken)
    return dataPass;
};

export const updateTeleHealthService = (statusType,bookingId,callId,date,time,bookingNotes, cancelToken) => {
    const params = { 'statusType': statusType, 'bookingId': bookingId, 'callId': callId,'date': date,'time': time,'bookingNotes': bookingNotes }
    var dataPass = common_axios.post(baseURL + 'update_telehealth_service', params, cancelToken)
    return dataPass;
};


export const saveAppointmentEditBreak = (breakId, end, cancelToken) => {
    const params = { 'breakId': breakId, 'end': end }
    var dataPass = common_axios.post(baseURL + 'save_appointment_edit_break', params, cancelToken)
    return dataPass;
};

export const fetchBreakReasons = (cancelToken) => {
    const params = {}
    var dataPass = common_axios.post(baseURL + 'fetch_break_reasons', params, cancelToken)
    return dataPass;
};