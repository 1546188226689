import { EditOutlined } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Link, useNavigate } from 'react-router-dom';
import { fetchConditions } from '../../../services/medengage/Service';
import axios from "axios";
import { exportTableData } from '../../utils/CommonLibrary';


function CareNavigatorListTable({ data }) {
    const [searchQuery, setSearchQuery] = useState('');
    const [selectedProgram, setSelectedProgram] = useState('All');
    const [selectedStatus, setSelectedStatus] = useState('All');
    const [conditions, setConditions] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        getConditions();
    }, [])

    const getConditions = async () => {
        const request = axios.CancelToken.source();
        let ct = {
            cancelToken: request.token
        }
        await fetchConditions(ct)
            .then((response) => {
                if (response.data.code === 200) {
                    setConditions(response.data.data.conditions);
                }

            }).catch(function (error) {
                console.log(error);
            });
        return () => request.cancel();
    }

    const columns = [
        {
            name: 'Care Navigator',
            selector: row => row.userName,
            sortable: true,
        },
        {
            name: 'Supervisor',
            selector: row => row.supervisorName,
            sortable: true,
        },
        {
            name: 'Email',
            selector: row => row.email,
            sortable: true,
        },
        {
            name: 'Mobile No',
            selector: row => row.mobile,
            sortable: true,
        },
        {
            name: 'Rating',
            selector: row => row.rating,
            sortable: true,
        },
        {
            name: 'Condition Name',
            selector: row => row.carePrograms,
            sortable: true,
        },
        {
            name: 'Status',
            selector: row => row.status,
            sortable: true,
            cell: row => (
                <span style={{ color: row.status === 'Active' ? '#01CD88' : '#FF0000' }}>
                    {row.status}
                </span>
            ),
        },
        {
            name: 'Actions',
            selector: row => row.status,
            // sortable: true,
            cell: row => (
                <IconButton
                    onClick={() => goToNavigatorEdit(row.id)}
                    color='primary'
                >
                    <EditOutlined />
                </IconButton>
            ),
        },
    ];


    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    const handleClear = () => {
        setSearchQuery('');
        setSelectedProgram('Diabetes Mellitus');
        setSelectedStatus('All');
    };

    const filteredData = data.filter(user => {
        const matchesSearchQuery =
            user.userName.toLowerCase().includes(searchQuery.toLowerCase()) ||
            user.email.toLowerCase().includes(searchQuery.toLowerCase());

        //const matchesProgram = selectedProgram === 'Diabetes Mellitus' || selectedProgram === 'All';
        const matchesProgram = user.carePrograms.includes(selectedProgram) || selectedProgram === 'All';

        const matchesStatus = selectedStatus === 'All' || user.status === selectedStatus;

        return matchesSearchQuery && matchesProgram && matchesStatus;
    });

    const goToNavigatorEdit = (id) => {
        navigate(`/edit-care-navigator/edit/${id}`);
    }

    const handleExport = () => {

        const modifiedData = filteredData.map(item => {
            return {
                'Care Navigator': item.userName,
                'Supervisor': item.supervisorName,
                'Email': item.email,
                'Mobile': item.mobile,
                'Rating': item.rating,
                'Condition Name': item.carePrograms,
                'Status': item.status,
            };
        });

        modifiedData.forEach(function (v) { delete v.capacity; delete v.id; delete v.showTransfer; delete v.totalMembers; delete v.visibility; });

        exportTableData("CareNavigatorData", modifiedData);
    };

    return (
        <>
            <div className='container-fluid'>
                <div className='row mb-3'>
                    <div className='col-md d-flex px-1'>
                        <div className="form-group" style={{ width: "100%" }}>
                            <input
                                type="text"
                                placeholder="Search By Name or Email ID"
                                value={searchQuery}
                                onChange={e => setSearchQuery(e.target.value)}
                                className="form-control input-field-ui ps-5"
                            />
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/ljce8/fac3csq13o.png" className="input-icon-seacrh" alt="" />
                        </div>
                    </div>
                    <div className='col-md-2 px-1'>
                        <select
                            value={selectedProgram}
                            onChange={e => setSelectedProgram(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Condition--</option>
                            {/*<option value="Diabetes Mellitus">Diabetes Mellitus</option>*/}
                            {
                                conditions.map((item, index) => {
                                    return <option value={item.conditionName}>{item.conditionName}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className='col-md-2 px-1'>
                        <select
                            value={selectedStatus}
                            onChange={e => setSelectedStatus(e.target.value)}
                            className='form-select input-field-ui'
                        >
                            <option value="All">--Select Status--</option>
                            <option value="Active">Active</option>
                            <option value="Inactive">Inactive</option>
                        </select>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleClear} className='btn btn-primary clear-search-btn'>Clear</button>
                    </div>
                    <div className='col-md-auto pe-1'>
                        <Link to="/add-care-navigator" type='button' className='btn btn-primary add-new-program-btn'>Add New Care Navigator
                        </Link>
                    </div>
                    <div className='col-md-auto px-1'>
                        <button onClick={handleExport} className="btn btn-primary btn-primary clear-search-btn"><i className="bi bi-download"></i> Export</button>
                    </div>
                </div>
            </div>
            <DataTable
                columns={columns}
                data={filteredData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                noFooter
                customStyles={customStyles}
            />
        </>
    );
}

export default CareNavigatorListTable;
