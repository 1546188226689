import React, { useState } from 'react';
import ContentMemberTransferPagev2 from './componenents/MemberTransferPage/ContentMemberTransferPagev2';


function MemberTransferPage() {

  const [title, setTitle] = useState('Member Transfer');

  return (
    <>
      <ContentMemberTransferPagev2 />
    </>
  );
}

export default MemberTransferPage;
