import {configureStore} from '@reduxjs/toolkit';
import webexSlice from './webexSlice'; 


const store = configureStore({
    reducer: {
        webex: webexSlice
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }),
});


export default store; 