import React, { useState } from 'react';
import { Modal, Tab, Tabs } from 'react-bootstrap';
import './FormsModal.css';
import LabRequestMetaDocForms from '../LabRequestForms/LabRequestMetaDocForms';


const FormsModalMetaDoc = ({ header, memberBasicInfo, showHRAModal, handleShowHRAModal, handleCloseHRAModal, selectedServiceRecord, callHomeBasedServicesHistory, selectedBulkServices }) => {

    const closeModal = async (e) => {
        handleCloseHRAModal();
    };

    return (
        <>
            <Modal id="wpnb2" className='HealthManagement' centered show={showHRAModal} onHide={handleCloseHRAModal} size="xl" backdrop="static" keyboard={false} fullscreen={false}>
                <Modal.Header closeButton>
                    <h1 className='modal-title'>{header} {selectedServiceRecord.serviceName != "" && ` - ${selectedServiceRecord.serviceName}`}</h1>
                </Modal.Header>
                <Modal.Body>
                    {header == 'Lab Request' && <LabRequestMetaDocForms closeModal={closeModal} selectedServiceRecord={selectedServiceRecord} callHomeBasedServicesHistory={callHomeBasedServicesHistory} />}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default FormsModalMetaDoc;