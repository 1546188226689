import React, { useState } from 'react';
import DataTable from 'react-data-table-component';
import { Button,Modal } from 'react-bootstrap';
import {createTempLink } from  "../../../services/met/Service";
import { ModalBoxButton } from '../../utils/CommonLibrary';
import { Link } from 'react-router-dom';

function ProritizeDataTable({ logData }) {

    const [showModal, setShowModal] = useState(false);
    const [uploadId, setUploadId] = useState(false);
    const handleClose = () =>setShowModal(false);
    const [showCancel, setShowCancel] = useState(false);
    const [showModalButton, setShowModalButton] = useState(false);
    const [showPopupButton, setShowPopupButton] = useState(false);
    const [modalBodyNew, setModalBodyNew] = useState('');
    const [showClose, setShowClose] = useState(true);
    const [modalTitle, setModalTitle] = useState('');
    const handleCloseButton = () =>{  setShowModalButton(false);}
    const [modalClass, setModalClass] = useState('md');

    const columns = [
        {
            name: 'Date Removed',
            selector: row => row.createdTime,
            sortable: true,
        },
        {
            name: 'Proritize By',
            selector: row => row.userName,
            sortable: true,
        },
        {
            name: 'Total Members',
            selector: row => row.memberCount,
            sortable: true,
        },
        {
            name: 'Remark',
            selector: row => row.remarks,
            sortable: true,
        },
       
        {
            name: 'Options',
            selector: row => row.url,
            sortable: true,
            cell: row => (
                <>
                    <Link ><i className="bi bi-download" onClick={(e) => downloadFileTemp(row.url)}></i></Link>
                </>
            ),
        },
    ];

    const customStyles = {
        headCells: {
            style: {
                backgroundColor: '#D4E6EF',
                fontWeight: '500',
                fontSize: '14px',
                whiteSpace: 'normal',
            },
        },
        rows: {
            style: {
                whiteSpace: 'normal',
            },
        },
        cells: {
            style: {
                whiteSpace: 'normal',
            },
        },
    };

    // const openInsightModal = (uniqueUploadId) => {
    //     insightFetchdata(uniqueUploadId)
    //     .then((response) => {
    //         const responseData = response.data.data
    //         setShowCancel(responseData.showCancel)
    //         setShowModal(true);
    //     });
    // };

    const downloadFileTemp = (url) => {
        createTempLink(url)
        .then((response) => {
            window.location.href = response?.data?.data?.fileUrl;
        });
    };

    // const updateApproveStatus = (uniqueUploadId,action) => {
    //     updateFileUploadStatus(action,uniqueUploadId,'','checker')
    //     .then((response) => {
    //         if(response.data.code == 200){
    //         setShowPopupButton(true);
    //         setShowClose(false);
    //         }else{
    //         setShowPopupButton(false);
    //         setShowClose(true);
    //         }
    //         setModalBodyNew(response.data.data.message)
    //         setModalTitle('Message!');
            
    //         setShowModalButton(true);
    //     }
    //     );
    // };

    return (

        <>

            <DataTable
                columns={columns}
                data={logData}
                pagination
                paginationPerPage={10}
                striped
                responsive
                noHeader
                customStyles={customStyles}
            />

            {/* {showModalButton ? <ModalBoxButton handleClose={handleCloseButton} showModaln={showModalButton} titlen={modalTitle} bodyn={modalBodyNew} type={modalClass}  button={showPopupButton ? handleCloseButton : undefined}  hideButton={showClose}/>:null} */}

            {/* <Modal dialogClassName="modal-xl modal-dialog-centered" show={showModal} onHide={handleClose} id="updateCapacity" backdrop="static" keyboard={false}>
                <Modal.Header closeButton>
                    <Modal.Title 
                        style={{
                        width: "90%",
                        fontSize:"18px",
                        }}
                    >
                        File Checker
                    </Modal.Title>
                    <hr/>
                </Modal.Header>
                <Modal.Body >
                <div className="col-md-12">
                    <div className="row">
                        <div  className="col-md-12">
                            {!showCancel && <button type="button" className="btn btn-primary btn-primary program-save-btn" onClick={() => updateApproveStatus(uploadId,'inprogress')}>Approve</button> }
                            {!showCancel && <button type="button" className="btn btn-primary btn-primary program-save-btn" onClick={() => updateApproveStatus(uploadId,'rejected')}>Reject</button> }
                            {showCancel && <button type="button" className="btn btn-primary btn-primary program-save-btn" onClick={() => updateApproveStatus(uploadId,'cancelled')}>Cancel</button> }
                            
                        </div>
                    </div>
                </div>
                </Modal.Body>
            </Modal> */}

        </>
    );
}

export default ProritizeDataTable;
