import React, { useState, useEffect } from "react";
import {
  fetch_program_data,
  fetch_single_frequency_configuration_template,
  fetch_whatsapp_template_data,
  frequency_configuration_template_creation,
  single_whatsapp_template,
} from "../../../services/mtp/Service";
import { Commingsoon, LoaderLoader } from "../../utils/CommonLibrary";
import ContentSMSNew from "../ContentConfiguration/ContentSMSNew";
import { Link } from "react-router-dom";

function ContentConfigurationList() {
  const [programData, setProgramData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [templates, setTemplates] = useState([]);
  

  const [whatsAppTemplate, setWhatsAppTemplateData] = useState([]);
  const [singleWhatsAppTemplateData, setSingleWhatsAppTemplateData] =
    useState("");
  const [selectedValue, setSelectedValue] = useState("");
  const [templateFrequency, setTemplateFrequency] = useState("");
  const [scheduleSend, setScheduleSend] = useState("Immediate");
  const [followUpMessages, setFollowUpMessages] = useState([
    { message: "", frequency: "", fschedule: "", templateId: "" },
  ]);
  const [errors, setErrors] = useState({});

  const [templateMessage, setTemplateMessage] = useState("");
  const [isScheduleSendButtonVisible, setIsScheduleSendButtonVisible] =
    useState(true);

  useEffect(() => {
    fetchWhatAppsTemplateData();
  }, []);

  const fetchWhatAppsTemplateData = async () => {
    try {
      const response = await fetch_whatsapp_template_data();
      setWhatsAppTemplateData(response.data.data || []);
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (event) => {
    const value = event.target.value;
    setSelectedValue(value);
    templateSelection(value);
  };

  const handleFrequencyChange = (event) => {
    const value = event.target.value;
    setTemplateFrequency(value);
  };

  const handleScheduleSendChange = (event) => {
    const value = event.target.value;
    setScheduleSend(value);
  };

  // const handleScheduleSendChange = (value) => {
  //     setScheduleSend(value);
  //     setIsScheduleSendButtonVisible(false);
  // };

  // const handleFollowUpScheduleChange = (index, event) => {
  //     const newFollowUpMessages = [...followUpMessages];
  //     newFollowUpMessages[index].fschedule = event.target.value;
  //     setFollowUpMessages(newFollowUpMessages);
  // };

  const handleFollowUpMessageChange = (index, event) => {
    const newFollowUpMessages = [...followUpMessages];
    newFollowUpMessages[index].message = event.target.value;
    setFollowUpMessages(newFollowUpMessages);
  };

  const handleFollowUpFrequencyChange = (index, event) => {
    const newFollowUpMessages = [...followUpMessages];
    newFollowUpMessages[index].frequency = event.target.value;
    setFollowUpMessages(newFollowUpMessages);
  };

  const handleFollowUpScheduleChange = (index, event) => {
    const newFollowUpMessages = [...followUpMessages];
    newFollowUpMessages[index].fschedule = event.target.value;
    setFollowUpMessages(newFollowUpMessages);
  };

  const handleFollowUpTemplateChange = async (index, event) => {
    const newFollowUpMessages = [...followUpMessages];
    const templateId = event.target.value;
    newFollowUpMessages[index].templateId = templateId;

    try {
      setIsLoading(true);
      const resp = await single_whatsapp_template(templateId);
      if (resp.data.code === 200) {
        newFollowUpMessages[index].message =
          resp.data.data[0].templateName || "";
      } else {
        newFollowUpMessages[index].message = "";
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }

    setFollowUpMessages(newFollowUpMessages);
  };

  const addFollowUpMessage = () => {
    setFollowUpMessages([
      ...followUpMessages,
      { message: "", frequency: "", fschedule: "", templateId: "" },
    ]);
  };

  const removeFollowUpMessage = (index) => {
    const newFollowUpMessages = [...followUpMessages];
    newFollowUpMessages.splice(index, 1);
    setFollowUpMessages(newFollowUpMessages);
  };

  const validateForm = () => {
    const newErrors = {};

    if (!selectedValue) {
      newErrors.selectedValue = "Template selection is required";
    }

    if (singleWhatsAppTemplateData.trim().length < 10) {
      newErrors.singleWhatsAppTemplateData =
        "WhatsApp message is required.";
    }

    // if (!templateFrequency) {
    //   newErrors.templateFrequency = "Template frequency is required";
    // }

    if (!scheduleSend) {
      newErrors.scheduleSend = "Schedule is required.";
    }

    followUpMessages.forEach((followUp, index) => {
      if (followUp.message.trim().length < 2) {
        newErrors[`followUpMessage${index}`] = `Follow-up message ${
          index + 1
        } is required.`;
      }
    //   if (!followUp.frequency) {
    //     newErrors[
    //       `followUpFrequency${index}`
    //     ] = `Frequency for follow-up message ${index + 1} is required`;
    //   }
      if (!followUp.fschedule) {
        newErrors[
          `followUpSchedule${index}`
        ] = `Schedule follow-up message ${index + 1} is required`;
      }
      if (!followUp.templateId) {
        newErrors[
          `followUpTemplate${index}`
        ] = `Template selection for follow-up message ${index + 1} is required`;
      }
    });

    setErrors(newErrors);

    return Object.keys(newErrors).length === 0;
  };

  const templateSelection = async (templateId) => {
    try {
      setIsLoading(true);
      const resp = await single_whatsapp_template(templateId);
      if (resp.data.code === 200) {
        setSingleWhatsAppTemplateData(resp.data.data[0].templateName || "");
      } else {
        setSingleWhatsAppTemplateData("");
      }
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) {
      return;
    }
    setIsLoading(true);
    const formData = {
      templateId: selectedValue,
      message: singleWhatsAppTemplateData,
      frequency: templateFrequency,
      scheduleSend: scheduleSend,
      followUpMessages: followUpMessages,
      labelValue: 1,
      labelDepth: 0,
      labelApoinment: 1,
      type: "whatsApp",
    };

    try {
      // Submit the formData to your backend service here
      let result = await frequency_configuration_template_creation(formData);
      if (result.data.code === 200) {
        setTemplateMessage(result.data.data.message || []);
        setSelectedValue();
        setSingleWhatsAppTemplateData("");
        setTemplateFrequency("");
        setScheduleSend("");
        setFollowUpMessages([
          { message: "", frequency: "", fschedule: "", templateId: "" },
        ]);
        setErrors({});
      } else {
        setTemplateMessage([]);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error submitting form data:", error);
    }
  };

  useEffect(() => {
    fetchProgramData();
  }, []);

  // fetching program data
  async function fetchProgramData() {
    try {
      let resp = await fetch_program_data();
      setIsLoading(true); //to show loader
      if (resp.data.code === 200) {
        setIsLoading(false); //hide loader
        setProgramData(resp.data.data);
      }
    } catch (error) {
      console.error(error);
      setIsLoading(false); //hide loader
    }
  }

  async function getTemplates(templateType, programId) {
    try {
      setIsLoading(true);
      setTemplateMessage([]);
      setSelectedValue();
      setSingleWhatsAppTemplateData("");
      setTemplateFrequency("");
      setScheduleSend("");
      setFollowUpMessages([
        { message: "", frequency: "", fschedule: "", templateId: "" },
      ]);
      setErrors({});
      const response = await fetch_single_frequency_configuration_template({
        templateType,
        programId,
      });

      if (response.data.code === 200) {
        setIsLoading(false);
        setTemplates(response.data.data);
      }
    } catch (e) {
      setIsLoading(false);
    }
  }

  return (
    <>
      <div className="row align-items-center pb-3 sticky-top-bar">
        <div className="col-md">
          <h4 className="mb-0 content-main-title">Configurations - Phase 2</h4>
        </div>
        {/* <div className="col-md text-end">
          <Link to="" className="btn btn-primary add-new-program-btn">
            <i className="bi bi-card-checklist me-2"></i>
          </Link>
        </div> */}
      </div>
      <div className='reports-programs mb-3'>
                    <div className=" row align-items-center">
                    <div className="col-md-auto">
                            <img src="https://storage.googleapis.com/ksabupatest/2024/09/24/3jbh8/58g4l8s8ca.png" alt="Invited Members icon" style={{height:"140px"}}/>
                        </div>
                        <div className="col-md">
                            <p className="mb-0">The Communication Configuration module allows you to easily manage all communications sent to members for various events through multiple channels like Email, SMS, WhatsApp, and App Push Notifications. This is crucial for engaging members effectively.</p>
                        </div>
                    </div>
                </div>
      
      <div className="container-fluid px-0 mb-3">
        <div className="content-ContentConfigurationLis mb-3">
          <div className="row">
            {/* <div className="col mt-2 px-4">
                            <h3 className="main-title">Configuration List Page </h3>
                        </div> */}
            {/* <div className="col-auto mt-2 px-4">
                            <a className="btn btn-primary devision-add-btn" href="/"><i className="bi bi-plus-circle me-2"></i> Add</a>
                        </div> */}
            <div className="col-md-12">
              <div className="body-tree-view-configuration">
                <LoaderLoader isLoading={isLoading} />
                {/* Configuration List 1 */}
                {programData.map((firstItem, index) => (
                  <details open key={index}>
                    <summary>{firstItem.programName} </summary>

                    {firstItem.children ? (
                      <>
                        {firstItem.children.map((secondItem, index2) => (
                          <details open key={index2}>
                            <summary className="row">
                              {/* <span className="col-md-3 ps-0">{secondItem.programName}</span> */}
                              <span className="col-md-3 ps-0">
                                Care Navigator Consultation
                              </span>
                              <span className="col-md-8">
                                <a
                                  className="btn btn-primary whatsApp-message-btn me-2"
                                  href="/"
                                  data-bs-toggle="modal"
                                  data-bs-target="#ConfigurationListModal"
                                  onClick={(e) =>
                                    getTemplates(
                                      "whatsapp",
                                      secondItem.programId
                                    )
                                  }
                                >
                                  <i className="bi bi-whatsapp me-1"></i>{" "}
                                  {secondItem.whatsAppCount} WhatsApp message
                                </a>
                                <a
                                  className="btn btn-primary sms-message-btn me-2"
                                  href="/"
                                  data-bs-toggle="modal"
                                  data-bs-target="#ConfigurationListSMSModal"
                                  onClick={(e) =>
                                    getTemplates("sms", secondItem.programId)
                                  }
                                >
                                  <i className="bi bi-chat-left-dots me-1"></i>{" "}
                                  {secondItem.smsCount} SMS
                                </a>
                                <a
                                  className="btn btn-primary Email-message-btn me-2"
                                  href="/"
                                  data-bs-toggle="modal"
                                  data-bs-target="#upcomingFeatureModal"
                                >
                                  <i className="bi bi-envelope me-1"></i> 0
                                  Email message
                                </a>
                                <a
                                  className="btn btn-primary Notification-message-btn me-2"
                                  href="/"
                                  data-bs-toggle="modal"
                                  data-bs-target="#upcomingFeatureModal"
                                >
                                  <i className="bi bi-bell me-1"></i> 0
                                  Notification
                                </a>
                              </span>
                            </summary>
                            <ul>
                              {secondItem.children ? (
                                <>
                                  {/* {   
                                                                        secondItem.children.map((thirdItem, thirdIndex)=>(
                                                                            <li key={thirdIndex}><span>{thirdItem.programName}</span></li>
                                                                        ))
                                                                    } */}
                                </>
                              ) : (
                                ""
                              )}

                              {/* <li><span>Low risk</span></li>
                                                            <li><span>Moderate-to-High Risk</span></li>
                                                            <li><span>High Risk</span></li> */}
                            </ul>
                          </details>
                        ))}
                      </>
                    ) : (
                      ""
                    )}
                  </details>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Commingsoon />

      <div
        className="modal fade"
        id="ConfigurationListModal"
        tabIndex="-1"
        aria-labelledby="ConfigurationListModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-subtitle mb-0">WhatsApp Communication for Care Navigator Consultation</h4>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            {templateMessage ? (
              <p style={{ color: "green", textAlign: "center" }}>
                {templateMessage}
              </p>
            ) : (
              ""
            )}
            <div className="modal-body pt-0 px-1">
              <div className="row">
                <form onSubmit={handleSubmit}>
                  <div className="col-md-12 mb-3 px-2">
                    <div className="modal-bg">
                      <div className="row ">
                      
                      <label
                                htmlFor="inputMessage2"
                                className="col-form-label pt-0">
                                Service Confirmation Message
                              </label>
                              <hr className="mt-2" />
                        {/* <div className="col-6 d-flex">
                          <i className="bi bi-bell me-2"></i>
                          <select
                            className="form-select input-field-ui-select"
                            id="templateFrequency"
                            name="templateFrequency"
                            value={templateFrequency}
                            aria-label="Default select example"
                            onChange={handleFrequencyChange}
                          >
                            <option value="">Select Frequency</option>
                            <option value="1">1 time</option>
                            <option value="2">2 time</option>
                            <option value="3">3 time</option>
                            <option value="4">4 time</option>
                            <option value="5">5 time</option>
                          </select>
                        </div> */}

                        <div className="col-12 d-flex align-items-center">
                          <i className="bi bi-clock me-2"></i>
                          <select
                            className="form-select input-field-ui"
                            value={scheduleSend}
                            aria-label="Default select example"
                            onChange={handleScheduleSendChange}
                          >
                            {/* <option value="">Select Schedule Time</option> */}
                            <option value="immediate" selected>Immediate</option>
                            {/* <option value="after 15 mins">After 15 mins</option>
                            <option value="after 30 mins">After 30 mins</option>
                            <option value="after 1 hrs">After 1 hrs</option> */}
                          </select>
                          {/* <span>{template.duration}</span> */}
                        </div>

                        {/* <div className="col-6">
                          {errors.templateFrequency && (
                            <div className="text-danger">
                              {errors.templateFrequency}
                            </div>
                          )}
                        </div> */}
                        <div className="col-12">
                          {errors.scheduleSend && (
                            <div className="text-danger">
                              {errors.scheduleSend}
                            </div>
                          )}
                        </div>

                        
                        {/* <div className="row "> */}
                          <div className="col-md-12 mt-3">
                            {/* <label
                              htmlFor="templateId"
                              className="col-form-label pt-0"
                            >
                              Template
                            </label> */}

                            <select
                              className="form-select input-field-ui"
                              id="templateId"
                              value={selectedValue}
                              aria-label="Default select example"
                              onChange={handleChange}
                            >
                              <option value="">Select Template</option>
                              {whatsAppTemplate.map((option) => (
                                <option
                                  key={option.templateId}
                                  value={option.templateId}
                                >
                                  {option.whatsAppTemplateId}
                                </option>
                              ))}
                            </select>
                            {errors.selectedValue && (
                              <div className="text-danger">
                                {errors.selectedValue}
                              </div>
                            )}
                          </div>
                        {/* </div> */}
                        <p className="mt-0"></p>
                        <div className="col-12">
                          {/* <label
                            htmlFor="inputMessage2"
                            className="col-form-label pt-0"
                          >
                            Service Confirmation Message
                          </label> */}
                          {/* <textarea className="form-control input-field-ui mb-1" value={template.template} id="inputMessage2" rows="3"></textarea> */}
                          <textarea
                            className="form-control input-field-ui mb-1"
                            id="inputMessage2"
                            rows="3"
                            value={singleWhatsAppTemplateData}
                            onChange={(e) =>
                              setSingleWhatsAppTemplateData(e.target.value)
                            }
                            readOnly="readOnly"
                            disabled
                            placeholder="Please select Template to see Whatsapp Message Text."
                          ></textarea>
                          {errors.singleWhatsAppTemplateData && (
                            <div className="text-danger">
                              {errors.singleWhatsAppTemplateData}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {followUpMessages.map((followUp, index) => (
                    <>
                      <div className="col-md-12 mb-3 px-2">
                        <div className="modal-bg">
                          <div className="row ">
                          <label
                                htmlFor="inputMessage2"
                                className="col-form-label pt-0">
                                Follow up message - {index+1}
                              </label>
                              <hr className="mt-2" />
                            {/* <div className="col-6 d-flex">
                              <i className="bi bi-bell me-2"></i>
                              <select
                                className="form-select input-field-ui-select"
                                value={followUp.frequency}
                                onChange={(e) =>
                                  handleFollowUpFrequencyChange(index, e)
                                }
                                aria-label="Default select example"
                              >
                                <option value="">Select Frequency</option>
                                <option value="1">1 Time</option>
                                <option value="2">2 Times</option>
                                <option value="3">3 Times</option>
                                <option value="4">4 Times</option>
                                <option value="5">5 Times</option>
                              </select>
                            </div> */}
                            <div className="col-12 d-flex align-items-center">
                              <i className="bi bi-clock me-2"></i>
                              <select
                                className="form-select input-field-ui"
                                value={followUp.fschedule}
                                onChange={(e) =>
                                  handleFollowUpScheduleChange(index, e)
                                }
                                aria-label="Default select example"
                              >
                                <option value="">Select Schedule Time</option>
                                <option value="before 15 mins">
                                  before 15 mins
                                </option>
                                <option value="before 30 mins">
                                  before 30 mins
                                </option>
                                <option value="before 1 hrs">
                                  before 1 hrs
                                </option>
                              </select>
                            </div>
                            {/* <div className="col-6">
                              {errors[`followUpFrequency${index}`] && (
                                <div className="text-danger">
                                  {errors[`followUpFrequency${index}`]}
                                </div>
                              )}
                            </div> */}
                            <div className="col-12 d-flex">
                              {errors[`followUpSchedule${index}`] && (
                                <div className="text-danger">
                                  {errors[`followUpSchedule${index}`]}
                                </div>
                              )}
                            </div>

                            
                            <div className="col-md-12 mt-3">
                              {/* <label
                                htmlFor="templateId"
                                className="col-form-label pt-0"
                              >
                                Template
                              </label> */}
                              <select
                                className="form-select input-field-ui"
                                id={`followUpTemplate${index}`}
                                value={followUp.templateId}
                                onChange={(e) =>
                                  handleFollowUpTemplateChange(index, e)
                                }
                              >
                                <option value="">Select Template</option>
                                {whatsAppTemplate.map((option) => (
                                  <option
                                    key={option.templateId}
                                    value={option.templateId}
                                  >
                                    {option.whatsAppTemplateId}
                                  </option>
                                ))}
                              </select>
                              {errors[`followUpTemplate${index}`] && (
                                <div className="text-danger">
                                  {errors[`followUpTemplate${index}`]}
                                </div>
                              )}
                            </div>
                            <p className="mt-0"></p>
                            <div className="col-12">
                              {/* <label
                                htmlFor="inputMessage2"
                                className="col-form-label pt-0">
                                First Reminder
                              </label> */}
                              <textarea
                                className="form-control input-field-ui"
                                placeholder="Please select Template to see Whatsapp Message Text."
                                rows="3"
                                value={followUp.message}
                                onChange={(e) =>
                                  handleFollowUpMessageChange(index, e)
                                }
                                readOnly="readOnly"
                                disabled
                              />
                              {errors[`followUpMessage${index}`] && (
                                <div className="text-danger">
                                  {errors[`followUpMessage${index}`]}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                  <div className="col-md-12 text-center mb-4">
                    <p
                      type="button"
                      className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                      onClick={addFollowUpMessage}
                    >
                      <i className="bi bi-plus me-2"></i> Add More Follow Up</p>
                  </div>
                  <div className="col-md-12 my-2 text-center">
                    <button
                      type="submit"
                      className="btn btn-primary program-save-btn me-2"
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="btn btn-secondary clear-btn"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="ConfigurationListSMSModal"
        tabIndex="-1"
        aria-labelledby="ConfigurationListSMSModalLabel"
        aria-hidden="true"
      >
        <ContentSMSNew />
      </div>
    </>
  );
}

export default ContentConfigurationList;
