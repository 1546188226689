import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import exporting from 'highcharts/modules/exporting';
import { agent_correct_incorrect_graph } from '../../../services/met/Service';
import { formatDate } from '../../utils/CommonLibrary';

// Initialize exporting module
exporting(Highcharts);

const MemberIncorrectDataFunnelBar = ({startDate,endDate,agentId,requestFrom}) => {
  // Define your data


  const [loadingCount, setLoadingCount] = useState(0);
  const [graphData, setGraphData] = useState({});
  const [incorrectData, setIncorrectData] = useState([]);
  const [incorrectDataLabel, setIncorrectDataLabel] = useState([]);
  
  
  useEffect(() => {
    agentIncorrectGraph();    
}, [agentId,startDate,endDate]);

  const agentIncorrectGraph = async () => {
    try {
        let sdate = formatDate(startDate);
        let edate = formatDate(endDate);
        let dataRating= '2';
        let response = await agent_correct_incorrect_graph(dataRating,sdate,edate,agentId,requestFrom);
        if(response.data.code === 200){
            setGraphData(response.data.data);
            setLoadingCount(response.data.data.length);
        }
    } catch (error) {
        console.log(error);
    }
}

useEffect(() => {
  if (graphData.length > 0) {
    const data = graphData.map((item) => item.reason_count);
    const label = graphData.map((item) => item.reason);
    setIncorrectData(data);
    setIncorrectDataLabel(label);
    
  }
}, [graphData]);

  const yAxisJson = {
    title: {
      text: null
    }
  };

  const tooltipJson = {
    shared: true,
    valueSuffix: ''
  };

  const seriesData = [{
    name: 'Member Incorrect Data',
    data: incorrectData,
    color: '#0A79D9'
  }, ];

  const optionsMCI6 = {
    chart: {
      type: 'column',
      height: 200,
      spacing: [20, 0, 0, 0],
      options3d: {
        enabled: true,
        alpha: 0,
        beta: 10,
        depth: 50,
        viewDistance: 25
      }
    },
    title: {
      text: null,
      align: 'left',
      style: {
        fontSize: '20px',
        fontWeight: '600',
        color: '#333',
        fontFamily: "IBM Plex Sans, sans-serif"
      }
    },
    xAxis: {
      categories: incorrectDataLabel,
      gridLineWidth: 0
    },
    yAxis: yAxisJson,
    tooltip: tooltipJson,
    plotOptions: {
      column: {
        borderRadius: 5,
        pointPadding: 0.2,
        groupPadding: 0.1,
        stacking: null, // Use null for side-by-side columns
        dataLabels: {
          enabled: true,
          style: {
            textOutline: 'none'
          }
        }
      }
    },
    series: seriesData,
    credits: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    legend: {
      enabled: false
    }
  };

  return (
    <>
    {loadingCount > 0 ? (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={optionsMCI6}
      />
    </div>
    ) : (
      <div className="emty-column">
      <h5 className="emty-title-graph">There are no data to display</h5>
  </div>
    )} 
    </>
  );
};

export default MemberIncorrectDataFunnelBar;
